
.vc-online-users-counter {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    color: $color-success;
    font-weight: 600;

    i {
        color: $color-success;
        margin-right: 5px;
        animation: blink 2s steps(5, start) infinite;
    }
}

@keyframes blink {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink {
    to {
        visibility: hidden;
    }
}
