.main-content .card {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  box-shadow: 0 0 13px 0 rgba(82,63,105,.05);
  background-color: $color-white;
  border-radius: 4px;
  border:none;
    height: 100%;


  .card-header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    border-bottom: 1px solid $color-tertiary-light;
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: $color-white;

    .card-header-title {
      display: flex;
      align-items: center;
      & > *, h2 {
        margin: 0;
        padding: 0;
        font-size: 1.2rem;
        font-weight: 500;
        color: $color-grey-dark2;
      }
    }

    .card-header-btn {
      display: flex;
      align-items: center;
      align-content: flex-end;

    }

  } 

  .card-edit {
    padding: 10px 25px 0;
    text-align: right;
    & + .card-body {
      padding-top:0;
    }
  }

  .card-body {
    color: $color-grey-dark1;
    padding:25px;

    .card-submenu {
      li {
        a {
          padding: .9rem 1.05rem .9rem .6rem;
          margin: .4rem 0;
          color: $color-grey-dark2;
          font-size: 1.08rem;
          border-radius: 4px;
          i {
            width: 2rem;
          }
          .submenu-title {
            font-weight: 500;
            padding-left: .3rem;
          }
          &:hover {
            background: $color-grey-medium;
            text-decoration: none;
          }
        }
        &.active {
          a {
            background: $color-grey-medium;
          }
        }
      }
    }

    .card-text-label {
      color: $color-grey-dark2;
      font-weight: 500;
    }

    .card-text-desc {
      font-size: 1rem;
      color: $color-grey-dark1;
      padding-bottom: .5rem;
      font-weight: 500;
      display: block;
    }
    .card-text-value {
      font-size: 1.4rem;
      font-weight: 600;
      color: $color-grey-dark2;
      display: block;
    }
  }
}
