@mixin form-validation-state($state, $color, $icon) {
    /* __!__START */
    .#{$state}-feedback {
        display: none;
        width: 100%;
        margin-top: $form-feedback-margin-top;
        @include font-size($form-feedback-font-size);
        color: $color;
    }

    .#{$state}-tooltip {
        position: absolute;
        top: 100%;
        z-index: 5;
        display: none;
        max-width: 100%; // Contain to parent when possible
        padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
        margin-top: .1rem;
        @include font-size($form-feedback-tooltip-font-size);
        line-height: $form-feedback-tooltip-line-height;
        color: color-yiq($color);
        background-color: rgba($color, $form-feedback-tooltip-opacity);
        @include border-radius($form-feedback-tooltip-border-radius);
    }

    /** Small change here to support setting the "is-valid" or "is-invalid" on the same element as "was-validated" */
    .was-validated.is-#{$state} .form-control:not(.no-validation) {
        border-color: $color;

        @if $enable-validation-icons {
            padding-right: $input-height-inner;
            background-image: $icon;
            background-repeat: no-repeat;
            background-position: center right $input-height-inner-quarter;
            background-size: $input-height-inner-half $input-height-inner-half;
        }

        &:focus {
            border-color: $color;
            box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
        }

        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
            display: block;
        }
    }

    // stylelint-disable-next-line selector-no-qualifying-type
    textarea.form-control {
        .was-validated &:#{$state},
        &.is-#{$state} {
            @if $enable-validation-icons {
                padding-right: $input-height-inner;
                background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
            }
        }
    }

    /** Small change here to support setting the "is-valid" or "is-invalid" on the same element as "was-validated" */
    .was-validated .custom-select:#{$state}, .custom-select.is-#{$state}, .was-validated.is-#{$state} .custom-select {
        border-color: $color;

        @if $enable-validation-icons {
            padding-right: $custom-select-feedback-icon-padding-right;
            background: $custom-select-background, $icon $custom-select-bg no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size;
        }

        &:focus {
            border-color: $color;
            box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
        }

        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
            display: block;
        }
    }



    .form-control-file {
        .was-validated &:#{$state},
        &.is-#{$state} {
            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }
        }
    }

    .form-check-input {
        .was-validated &:#{$state},
        &.is-#{$state} {
            ~ .form-check-label {
                color: $color;
            }

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }
        }
    }

    .custom-control-input {
        .was-validated &:#{$state},
        &.is-#{$state} {
            ~ .custom-control-label {
                color: $color;

                &::before {
                    border-color: $color;
                }
            }

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }

            &:checked {
                ~ .custom-control-label::before {
                    border-color: lighten($color, 10%);
                    @include gradient-bg(lighten($color, 10%));
                }
            }

            &:focus {
                ~ .custom-control-label::before {
                    box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                }

                &:not(:checked) ~ .custom-control-label::before {
                    border-color: $color;
                }
            }
        }
    }

    // custom file
    .custom-file-input {
        .was-validated &:#{$state},
        &.is-#{$state} {
            ~ .custom-file-label {
                border-color: $color;
            }

            ~ .#{$state}-feedback,
            ~ .#{$state}-tooltip {
                display: block;
            }

            &:focus {
                ~ .custom-file-label {
                    border-color: $color;
                    box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                }
            }
        }
    }
    /* __!__END */
}
