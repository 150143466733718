.vc-form-errors {
    background: $color-danger;
    padding: 3px 15px;
    border-radius: 3px;
    color: $color-danger-invert;
    text-align: center;
    font-size: 0.95rem;
}

.vc-form-errors a {
    color: #fff;
    font-weight: bold;
    text-decoration: underline;
}
