@import '../../../vendor/bootstrap/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

.vc-form-select {
    outline: none;

    label {
        padding-top: 5px;
    }
}

.vc-form-select__select {
    position: relative;
    user-select: none;
    padding-right: calc(1.5em + 0.75rem);

    &--opened {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &--disabled {
        background-color: #e9ecef;
        pointer-events: none;
    }

    &::after {
        content: "\f0d7";
        font-family: "Font Awesome 5 Pro";
        font-weight: bold;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    &:hover {
        cursor: pointer;
    }
}

.vc-form-select__preview, .vc-form-select__placeholder {
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
}

.vc-form-select__preview > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.vc-form-select__placeholder {
    color: #6c757d;
}

//.vc-form-select__implicit-search {
//    position: absolute;
//    background: orange;
//    z-index: 111;
//    bottom: -28px;
//    left: 0;
//    right: 0;
//    padding: 3px 6px;
//    border-radius: 3px;
//    font-size: 0.8rem;
//}

.vc-form-select__choices {
    position: absolute;
    z-index: 100000;
    background: #fff;
    left: -1px;
    right: -1px;
    top: 100%;
    border: 1px solid #ced4da;
    border-top: 1px dashed #ced4da;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: -10px 0px 7px -7px rgba(0, 0, 0, 0.15), 10px 0px 7px -7px rgba(0,0,0,.15), 0 5px 5px rgba(0,0,0,.15);

    &--top {
        top: 0;
        border: 1px solid #ced4da;
        border-bottom: 1px dashed #ced4da;
        border-radius: 3px 3px 0 0;
        box-shadow: -10px 0px 7px -7px rgba(0, 0, 0, 0.15), 10px 0px 7px -7px rgba(0, 0, 0, 0.15), 0 -2px 5px rgba(0, 0, 0, 0.15);
        transform: translateY(-100%);
    }
}

.vc-form-select__implicit-search {
    position: relative;
    padding-left: 30px !important;
    border-bottom: 1px solid #ced4da;

    &::before {
        content: "\f002";
        font-family: "Font Awesome 5 Pro";
        font-weight: bold;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.vc-form-select__search-close-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #cdcdcd;
}

.vc-form-select__choice.vc-form-select__search {
    display: flex;
    align-items: center;

    .vc-form-input {
        flex: 1;
    }

    .vc-form-select__search-close-btn {
        position: static;
        transform: none;
        margin-left: 10px;
    }
}

.vc-form-select__choices-overflow-wrapper {
    overflow: auto;
    max-height: 224px;
    position: relative;
}

.vc-form-select__choice {
    border-bottom: 1px solid #eff0f6;
    padding: 6px 10px;
    display: flex;
    align-items: center;

    .form-group {
        flex: 0;
        margin: 0;

        label {
            padding: 0;
        }

        .custom-control-input {
            &:valid ~ .custom-control-label::before {
                border-color: #adb5bd;
            }

            &:checked ~ .custom-control-label {
                &::before {
                    color: $color-primary-invert;
                    background: $color-primary-invert;
                    border-color: $color-primary-invert;
                }

                &::after {
                    color: $color-primary;
                }
            }
        }
    }

    &:last-child {
        border-bottom: none;
    }

    &:hover:not(.vc-form-select__choice--not-interactive) {
        cursor: pointer;
        background: #f3f3f3;
    }

    &--selected, &--selected:hover {
        background: $color-primary !important;
        color: $color-primary-invert;
    }

    &--suggested {
        background: rgba($color-primary, 0.1) !important;
        font-weight: bold;
    }

    &--selected.vc-form-select__choice--suggested {
        background: tint($color-primary, 20%) !important;
    }

    &-no-results {
        text-align: center;
        display: block;
        padding: 10px;
        color: #6c757d;

        i {
            font-size: 5rem;
            color: #ddd;
            display: block;
        }
    }

    &-loading {
        padding: 12px;
        display: flex;
        justify-content: center;
    }
}

.vc-form-select__loader {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
}

.was-validated.is-valid .vc-form-select__select {
    background: $form-feedback-icon-valid $custom-select-bg no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size !important;
}

.was-validated.is-invalid .vc-form-select__select {
    background: $form-feedback-icon-invalid $custom-select-bg no-repeat $custom-select-feedback-icon-position / $custom-select-feedback-icon-size !important;
}

/**
 * In list
 */
.vc-list__header {
    .vc-form-select {
        .form-control.has-value {
            background-color: #fff3cd !important;
            border-color: #e8d89e !important;

            .vc-form-select__preview {
                color: #856644;
                font-weight: bold;
            }
        }
    }
}
