.fr-toolbar {
  color: $ui-text;
  background: $ui-bg;
  position: relative;
  font-family: $font-family;
  @include clearfix();
  @include box-sizing(border-box);
  @include user-select(none);
  @include border-radius($border-radius);
  text-align: left;
  border: $editor-border;
  text-rendering: optimizelegibility;
  @include font-smoothing();
  line-height: 1.2;
  padding-bottom: 0;
  @include transition(padding-bottom $toolbar-transition-timing);

  &::after {
    height: 0;
  }

  .fr-newline {
    clear: both;
    display: block;
    content: "";
    margin-left: $toolbar-newline-margin;
    margin-right: $toolbar-newline-margin;
    background: $toolbar-newline-color;
    height: $toolbar-newline-height;
    @include transition(height $toolbar-transition-timing);
  }

  &.fr-toolbar-open {
    .fr-newline {
      height: 0;
    }

    padding-bottom: $more-toolbar-btn-height;
  }

  .fr-float-right {
    float: right;
  }

  .fr-float-left {
    float: left;
  }

  .fr-more-toolbar {
    float: left;
    @include border-radius(0);
    background-color: $ui-bg-color-more;
    @include transition(height $toolbar-transition-timing);
    height: 0;
    z-index: 2;
    overflow: hidden;
    position: absolute;
    @include box-sizing(border-box);
    width: 100%;

    &.fr-expanded {
      height: $more-toolbar-btn-height;
    }

    &.fr-overflow-visible {
      overflow: visible;
    }

    & > .fr-command.fr-btn {
      margin-left: $btn-margin;
      margin-right: $btn-margin;
    }
  }

  .fr-btn-grp {
    display: inline-block;
    margin: $more-toolbar-margin;

    @media (max-width: $screen-sm) {
      margin: $more-toolbar-margin-sm;
    }
  }

  .fr-command.fr-btn.fr-open {
    margin-top: $btn-more-open-margin-top;
    margin-bottom: $btn-more-open-margin-bottom;
    @include border-radius($btn-border-radius $btn-border-radius 0 0);

    &:not(:hover):not(:focus):not(:active) {
      background: $ui-bg-color-more;
    }
  }

  &.fr-rtl {
    text-align: right;
  }

  &.fr-inline {
    display: none;
    @include box-shadow($popup-box-shadow);
    border: 0;
    white-space: nowrap;
    position: absolute;
    margin-top: 5px;
    z-index: 2147483630;

    &.fr-above {
      margin-top: -10px;
      border-top: 0;
    }

    .fr-newline {
      height: 0;
    }
  }

  &.fr-top {
    top: 0;
    border-bottom: 0;
    @include border-radius($editor-border-radius $editor-border-radius 0 0);
  }

  &.fr-bottom {
    bottom: 0;
    border-top: 0;
    padding-bottom: 0;
    @include border-radius(0 0 $editor-border-radius $editor-border-radius);

    .fr-newline {
      padding-top: 0;
      @include transition(padding-top $toolbar-transition-timing);
    }

    &.fr-toolbar-open .fr-newline {
      padding-top: $more-toolbar-btn-height;
      background: $white;
    }

    .fr-command.fr-btn.fr-open {
      margin-top: $btn-more-open-margin-bottom;
      margin-bottom: $btn-more-open-margin-top;
      @include border-radius(0 0 $btn-border-radius $btn-border-radius);
    }
  }

  &.fr-sticky-on {
    @include border-radius(0);
  }
}

.fr-separator {
  background: $separator-color;
  display: block;
  vertical-align: top;
  float: left;

  + .fr-separator {
    display: none;
  }

  &.fr-vs {
    height: ($btn-height - 2 * $btn-margin);
    width: $separator-size;
    margin: $separator-margin;
  }

  &.fr-hs {
    clear: both;
    width: calc(100% - (2 * #{$btn-margin}));
    margin: 0 $btn-margin;
    height: $separator-size;
  }

  &.fr-hidden {
    display: none !important;
  }
}

.fr-rtl .fr-separator {
  float: right;
}
.fr-toolbar.fr-inline .fr-separator.fr-hs {
  float: none;
}

.fr-toolbar.fr-inline .fr-separator.fr-vs {
  float: none;
  display: inline-block;
}

.second-toolbar {
  border: $editor-border;
  border-top: 0;
  background: $editor-bg;
  line-height: 1.4;
  @include border-radius(0 0 $editor-border-radius $editor-border-radius);
  @include clearfix();
}

#logo {
  float: left;
  outline: none;

  & > span{
    display: inline-block;
    float: left;
    font-family: sans-serif;
    padding: $logo-span-padding;
    font-size: $logo-span-font-size;
    font-weight: 500;
    color: $logo-color;
    @include transition(color $logo-transition-timing);
  }

  & > svg {
    height: $logo-svg-height;
    width: $logo-svg-width;
    margin: $logo-svg-margin;
    cursor: pointer;
  }

  & > svg * {
    @include transition(fill $logo-transition-timing);
  }

  &:hover, &:focus {
    & > span {
      color: $btn-active-hover-text;
    }

    & > svg .fr-logo {
      fill: $btn-active-hover-text;
    }
  }
}
