.vc-form-input {
    label {
        padding-top: 5px;
    }
}

/**
 * In list
 */
.vc-list__header {
    .vc-form-input {
        .form-control.has-value {
            background-color: #fff3cd;
            color: #856644;
            font-weight: bold;
            border-color: #e8d89e;
        }
    }
}
