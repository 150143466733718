
/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Colors
 * ---------------------------------------------------------------------------------------------------------------------
 */
// Main colors
$color-primary              : rgb(65, 81, 97);
$color-primary-dark         : rgb(51, 63, 75);
$color-primary-light        : rgb(90, 113, 135);
$color-primary-invert       : rgb(255, 255, 255);

$color-secondary            : rgb(143, 227, 192);
$color-secondary-invert     : rgb(255, 255, 255);
$color-secondary-dark       : rgb(103, 185, 151);

$color-tertiary             : rgb(100, 108, 154);
$color-tertiary-light       : rgb(239, 240, 246);
$color-tertiary-dark        : rgb(75, 81, 116);

$color-tertiary-invert      : rgb(255, 255, 255);
$color-quaternary           : rgb(0, 0, 0);
$color-quaternary-invert    : rgb(255, 255, 255);
$color-quinary              : rgb(0, 0, 0);
$color-quinary-invert       : rgb(255, 255, 255);

// States colors
$color-success              : rgb(92, 184, 92);
$color-success-invert       : rgb(255, 255, 255);
$color-info                 : rgb(3, 169, 244);
$color-info-invert          : rgb(255, 255, 255);
$color-warning              : rgb(255, 193, 7);
$color-warning-invert       : rgb(255, 255, 255);
$color-danger               : rgb(200, 40, 40);
$color-danger-invert        : rgb(255, 255, 255);
$color-muted                : rgb(153, 153, 153);
$color-muted-invert         : rgb(90, 90, 90);

$color-light                : rgb(255, 255, 255);
$color-dark                 : rgb(0, 0, 0);

// Color names
$color-white                : rgb(255, 255, 255);
$color-grey-light           : rgb(246, 246, 246);
$color-grey-medium          : rgb(231, 232, 239);
$color-grey                 : rgb(149, 156, 182);
$color-grey-dark1           : rgb(116, 120, 141);
$color-grey-dark2           : rgb(89, 93, 110);
$color-grey-dark3           : rgb(72, 75, 91);
$color-black                : rgb(0, 0, 0);

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Typography
 * ---------------------------------------------------------------------------------------------------------------------
 */
$font-family-sans-serif     : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace      : SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base           : $font-family-sans-serif;
$font-size-base-min         : 12px; // MUST be in px
$font-size-base-max         : 16px; // MUST be in px

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Sass MQ variables
 * https://github.com/sass-mq/sass-mq
 * ---------------------------------------------------------------------------------------------------------------------
 */
// To enable support for browsers that do not support @media queries,
// (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
// Create a separate stylesheet served exclusively to these browsers,
// meaning @media queries will be rasterized, relying on the cascade itself
$mq-responsive: true;

// Name your breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers.
$mq-breakpoints: (
    'mobile':  320px,
    'tablet':  768px,
    'desktop': 991px,
    'wide':    1300px,

    // Tweakpoints
    'mobileLandscape': 480px,
);

// Define the breakpoint from the $mq-breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (when $mq-responsive is set to 'false').
$mq-static-breakpoint: desktop;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width. For example: (mobile, tablet, desktop).
// $mq-show-breakpoints: (mobile, mobileLandscape, tablet, desktop, wide);
$mq-show-breakpoints: ();

/**
 * Z-indexes
 */
$z-indexes: (
    'below': -1,
    'default': 1,
    'position-fixed': 1000,
    'position-sticky': 1010,
    'modal': 5000,
);

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Define the default min and max width the "responsive-size" mixin is
 * based on when no size is defined when including the mixin.
 *
 * The size will be at the lowest value when the screen size is <= at $responsive-size-min-width
 * and at the highest value when the screen size is >= at $responsive-size-max-width.
 * ---------------------------------------------------------------------------------------------------------------------
 */
$responsive-size-min-width: 320px;
$responsive-size-max-width: 700px;
