.vc-application-access-rights-manager {
    .module {
        background: #f6f6f6;
        padding: 20px;
        border-radius: 3px;
        margin-bottom: 20px;
    }

    .add-module-wrapper {
        padding-bottom: 15px;
        border-bottom: 1px solid #eff0f6;
        margin: 0 -25px;

        .empty-text {
            display: none;
        }

        &--empty {
            border-top: 1px solid #eff0f6;
            margin-top: 30px;
            padding-top: 15px;

            .empty-text {
                display: block;
            }
        }
    }

    .features {
        padding: 25px;
        background: #e9ecef;
        border-radius: 3px;
    }

    .trash-column-previous {
        max-width: 100%;
        flex-grow: 1;
        padding-right: 0;
    }

    .trash-column {
        max-width: 30px;
        margin-top: 37px;
        padding: 0;
        text-align: center;
    }
}
