.header-desktop {
    display: flex;
    justify-content: space-between;
    background: $color-white;
    border-bottom: 1px solid $color-tertiary-light;
    height: 60px;

    .section__content {
        overflow: visible;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
    }
    .mess-dropdown, .email-dropdown, .notifi-dropdown {
        top: 51px;
    }
}

.header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .header-wrap .account-item > .content {
        display: none;
    }
}

@media (max-width: 991px) {
    .header-wrap {
        flex-direction: column;
        .mess-dropdown {
            left: 0;
            &::before {
                left: 0;
            }
        }
        .notifi-dropdown {
            left: -83px;
            &::before {
                left: 79px;
            }
        }
    }
}

.header-button {
    display: flex;
    align-items: center;
}

@media (max-width: 991px) {
    .header-button {
        margin-top: 30px;
        width: 100%;
        justify-content: space-between;
    }
}

.account-wrap {
    position: relative;
}

.account-item {
    cursor: pointer;
    > .content {
        padding: 10px;
        color: $color-tertiary;
        > a {
            color: $color-tertiary;
            text-transform: capitalize;
            font-weight: 600;
            font-size: 1rem;
            &:after {
                font-family: "Font Awesome 5 Pro";
                font-weight: 500;
                content: '\f078';
                display: inline-block;
                font-size: 12px;
                margin-left: 5px;
            }
            &:hover {
                color: $color-tertiary-dark;
            }
        }
    }

    .account-dropdown {
        right: 0;
        left: auto;
        padding:0;
        .info {
            padding: 15px;
            border-bottom: 1px solid $color-tertiary-light;
            font-weight: 500;
            .content {
                .name {
                    line-height: calc(20/16);
                    font-size: 1rem;
                    text-transform: uppercase;
                    a {
                        color: $color-tertiary-dark;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                .email {
                    color: $color-tertiary-dark;
                    line-height: calc(20/13);
                }
            }
        }

        .account-dropdown__footer {
            border-top: 1px solid $color-tertiary-light;
        }
        .account-dropdown__body a,
        .account-dropdown__footer a {
            display: block;
            color: $color-tertiary;
            padding: 10px 15px;
        }
        .account-dropdown__body a:hover,
        .account-dropdown__footer a:hover {
            background: $color-grey-light;
            text-decoration: none;
        }
        .account-dropdown__body a i,
        .account-dropdown__footer a i{
            line-height: 1;
            margin-right: 8px;
            font-size: 18px;
            vertical-align: middle;
        }
    }
}

.subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: $color-white;
    padding: 0 25px;
    height: 54px;
    border-bottom: 1px solid #eff0f6;
    position: relative;

    & > * {
        display: flex;
    }

    .subheader-toolbar > * {
        margin-right: 5px;

        &:last-child {
            margin-right: 0;
        }
    }
}
