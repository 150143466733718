.switch {
    &.switch-default {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 40px;
        height: 24px;
        background-color: transparent;
        cursor: pointer;
        .switch-input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
        .switch-label {
            position: relative;
            display: block;
            height: inherit;
            font-size: 10px;
            font-weight: 600;
            text-transform: uppercase;
            background-color: $color-white;
            border: 1px solid $color-grey-light;
            border-radius: 2px;
            transition: opacity .15s ease-out, background .15s ease-out;
        }
        .switch-input:checked ~ .switch-label {
            &::before {
                opacity: 0;
            }
            &::after {
                opacity: 1;
            }
        }
        .switch-handle {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 20px;
            height: 20px;
            background: #fff;
            border: 1px solid $color-grey-light;
            border-radius: 1px;
            transition: left .15s ease-out;
        }
        .switch-input:checked ~ .switch-handle {
            left: 18px;
        }
        &.switch-lg {
            width: 48px;
            height: 28px;
            .switch-label {
                font-size: 12px;
            }
            .switch-handle {
                width: 24px;
                height: 24px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 22px;
            }
        }
        &.switch-sm {
            width: 32px;
            height: 20px;
            .switch-label {
                font-size: 8px;
            }
            .switch-handle {
                width: 16px;
                height: 16px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 14px;
            }
        }
        &.switch-xs {
            width: 24px;
            height: 16px;
            .switch-label {
                font-size: 7px;
            }
            .switch-handle {
                width: 12px;
                height: 12px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 10px;
            }
        }
    }
    &.switch-text {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 48px;
        height: 24px;
        background-color: transparent;
        cursor: pointer;
        .switch-input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
        .switch-label {
            position: relative;
            display: block;
            height: inherit;
            font-size: 10px;
            font-weight: 600;
            text-transform: uppercase;
            background-color: $color-white;
            border: 1px solid $color-grey-light;
            border-radius: 2px;
            transition: opacity .15s ease-out, background .15s ease-out;
            &::before, &::after {
                position: absolute;
                top: 50%;
                width: 50%;
                margin-top: -.5em;
                line-height: 1;
                text-align: center;
                transition: inherit;
            }
            &::before {
                right: 1px;
                color: $color-grey-medium;
                content: attr(data-off);
            }
            &::after {
                left: 1px;
                color: $color-white;
                content: attr(data-on);
                opacity: 0;
            }
        }
        .switch-input:checked ~ .switch-label {
            &::before {
                opacity: 0;
            }
            &::after {
                opacity: 1;
            }
        }
        .switch-handle {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 20px;
            height: 20px;
            background: $color-white;
            border: 1px solid $color-grey-light;
            border-radius: 1px;
            transition: left .15s ease-out;
        }
        .switch-input:checked ~ .switch-handle {
            left: 26px;
        }
        &.switch-lg {
            width: 56px;
            height: 28px;
            .switch-label {
                font-size: 12px;
            }
            .switch-handle {
                width: 24px;
                height: 24px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 30px;
            }
        }
        &.switch-sm {
            width: 40px;
            height: 20px;
            .switch-label {
                font-size: 8px;
            }
            .switch-handle {
                width: 16px;
                height: 16px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 22px;
            }
        }
        &.switch-xs {
            width: 32px;
            height: 16px;
            .switch-label {
                font-size: 7px;
            }
            .switch-handle {
                width: 12px;
                height: 12px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 18px;
            }
        }
    }
    &.switch-icon {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 48px;
        height: 24px;
        background-color: transparent;
        cursor: pointer;
        .switch-input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
        .switch-label {
            position: relative;
            display: block;
            height: inherit;
            font-family: FontAwesome;
            font-size: 10px;
            font-weight: 600;
            text-transform: uppercase;
            background-color: $color-white;
            border: 1px solid $color-grey-light;
            border-radius: 2px;
            transition: opacity .15s ease-out, background .15s ease-out;
            &::before, &::after {
                position: absolute;
                top: 50%;
                width: 50%;
                margin-top: -.5em;
                line-height: 1;
                text-align: center;
                transition: inherit;
            }
            &::before {
                right: 1px;
                color: $color-grey-medium;
                content: attr(data-off);
            }
            &::after {
                left: $color-grey-medium;
                color: $color-grey-light;
                content: attr(data-on);
                opacity: 0;
            }
        }
        .switch-input:checked ~ .switch-label {
            &::before {
                opacity: 0;
            }
            &::after {
                opacity: 1;
            }
        }
        .switch-handle {
            position: absolute;
            top: 2px;
            left: 2px;
            width: 20px;
            height: 20px;
            background: $color-white;
            border: 1px solid $color-grey-light;
            border-radius: 1px;
            transition: left .15s ease-out;
        }
        .switch-input:checked ~ .switch-handle {
            left: 26px;
        }
        &.switch-lg {
            width: 56px;
            height: 28px;
            .switch-label {
                font-size: 12px;
            }
            .switch-handle {
                width: 24px;
                height: 24px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 30px;
            }
        }
        &.switch-sm {
            width: 40px;
            height: 20px;
            .switch-label {
                font-size: 8px;
            }
            .switch-handle {
                width: 16px;
                height: 16px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 22px;
            }
        }
        &.switch-xs {
            width: 32px;
            height: 16px;
            .switch-label {
                font-size: 7px;
            }
            .switch-handle {
                width: 12px;
                height: 12px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 18px;
            }
        }
    }
    &.switch-3d {
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 40px;
        height: 24px;
        background-color: transparent;
        cursor: pointer;
        .switch-input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
        .switch-label {
            position: relative;
            display: block;
            height: inherit;
            font-size: 10px;
            font-weight: 600;
            text-transform: uppercase;
            background-color: #f8f9fa;
            border: 1px solid $color-grey-light;
            border-radius: 2px;
            transition: opacity .15s ease-out, background .15s ease-out;
        }
        .switch-input:checked ~ .switch-label {
            &::before {
                opacity: 0;
            }
            &::after {
                opacity: 1;
            }
        }
        .switch-handle {
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            background: $color-white;
            border: 1px solid $color-grey-light;
            border-radius: 1px;
            transition: left .15s ease-out;
            border: 0;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        }
        .switch-input:checked ~ .switch-handle {
            left: 16px;
        }
        &.switch-lg {
            width: 48px;
            height: 28px;
            .switch-label {
                font-size: 12px;
            }
            .switch-handle {
                width: 28px;
                height: 28px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 20px;
            }
        }
        &.switch-sm {
            width: 32px;
            height: 20px;
            .switch-label {
                font-size: 8px;
            }
            .switch-handle {
                width: 20px;
                height: 20px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 12px;
            }
        }
        &.switch-xs {
            width: 24px;
            height: 16px;
            .switch-label {
                font-size: 7px;
            }
            .switch-handle {
                width: 16px;
                height: 16px;
            }
            .switch-input:checked ~ .switch-handle {
                left: 8px;
            }
        }
    }
}

.switch-pill .switch-label, .switch.switch-3d .switch-label, .switch-pill .switch-handle, .switch.switch-3d .switch-handle {
    border-radius: 50em !important;
}

.switch-pill .switch-label::before, .switch.switch-3d .switch-label::before {
    right: 2px !important;
}

.switch-pill .switch-label::after, .switch.switch-3d .switch-label::after {
    left: 2px !important;
}

.switch-primary > .switch-input:checked ~ {
    .switch-label {
        background: $color-primary !important;
        border-color: darken( $color-primary, 10% );
    }
    .switch-handle {
        border-color: darken( $color-primary, 10% );
    }
}

.switch-primary-outline > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-primary;
        &::after {
            color: $color-primary;
        }
    }
    .switch-handle {
        border-color: $color-primary;
    }
}

.switch-primary-outline-alt > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-primary;
        &::after {
            color: $color-primary;
        }
    }
    .switch-handle {
        background: $color-primary !important;
        border-color: $color-primary;
    }
}

.switch-secondary > .switch-input:checked ~ {
    .switch-label {
        background: $color-secondary !important;
        border-color: darken( $color-secondary, 10% );
    }
    .switch-handle {
        border-color: darken( $color-secondary, 10% );
    }
}

.switch-secondary-outline > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-secondary;
        &::after {
            color: $color-secondary;
        }
    }
    .switch-handle {
        border-color: $color-secondary;
    }
}

.switch-secondary-outline-alt > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-secondary;
        &::after {
            color: $color-secondary;
        }
    }
    .switch-handle {
        background: $color-secondary !important;
        border-color: $color-secondary;
    }
}

.switch-success > .switch-input:checked ~ {
    .switch-label {
        background: $color-success !important;
        border-color: darken( $color-success, 10% );
    }
    .switch-handle {
        border-color: darken( $color-success, 10% );
    }
}

.switch-success-outline > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-success;
        &::after {
            color: $color-success;
        }
    }
    .switch-handle {
        border-color: $color-success;
    }
}

.switch-success-outline-alt > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-success;
        &::after {
            color: $color-success;
        }
    }
    .switch-handle {
        background: $color-success !important;
        border-color: $color-success;
    }
}

.switch-info > .switch-input:checked ~ {
    .switch-label {
        background: $color-info !important;
        border-color: darken( $color-info, 10% );
    }
    .switch-handle {
        border-color: darken( $color-info, 10% );
    }
}

.switch-info-outline > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-info;
        &::after {
            color: $color-info;
        }
    }
    .switch-handle {
        border-color: $color-info;
    }
}

.switch-info-outline-alt > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-info;
        &::after {
            color: $color-info;
        }
    }
    .switch-handle {
        background: $color-info !important;
        border-color: $color-info;
    }
}

.switch-warning > .switch-input:checked ~ {
    .switch-label {
        background: $color-warning !important;
        border-color: darken( $color-warning, 10% );
    }
    .switch-handle {
        border-color: darken( $color-warning, 10% );
    }
}

.switch-warning-outline > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-warning;
        &::after {
            color: $color-warning;
        }
    }
    .switch-handle {
        border-color: $color-warning;
    }
}

.switch-warning-outline-alt > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-warning;
        &::after {
            color: $color-warning;
        }
    }
    .switch-handle {
        background: $color-warning !important;
        border-color: $color-warning;
    }
}

.switch-danger > .switch-input:checked ~ {
    .switch-label {
        background: $color-danger !important;
        border-color: darken( $color-danger, 10% );
    }
    .switch-handle {
        border-color: darken( $color-danger, 10% );
    }
}

.switch-danger-outline > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-danger;
        &::after {
            color: $color-danger;
        }
    }
    .switch-handle {
        border-color: $color-danger;
    }
}

.switch-danger-outline-alt > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-danger;
        &::after {
            color: $color-danger;
        }
    }
    .switch-handle {
        background: $color-danger !important;
        border-color: $color-danger;
    }
}

.switch-light > .switch-input:checked ~ {
    .switch-label {
        background: $color-light !important;
        border-color: darken( $color-light, 10% );
    }
    .switch-handle {
        border-color: darken( $color-light, 10% );
    }
}

.switch-light-outline > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-light;
        &::after {
            color: $color-light;
        }
    }
    .switch-handle {
        border-color: $color-light;
    }
}

.switch-light-outline-alt > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-light;
        &::after {
            color: $color-light;
        }
    }
    .switch-handle {
        background: $color-light !important;
        border-color: $color-light;
    }
}

.switch-dark > .switch-input:checked ~ {
    .switch-label {
        background: $color-dark !important;
        border-color: darken( $color-dark, 10% );
    }
    .switch-handle {
        border-color: darken( $color-dark, 10% );
    }
}

.switch-dark-outline > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-dark;
        &::after {
            color: $color-dark;
        }
    }
    .switch-handle {
        border-color: $color-dark;
    }
}

.switch-dark-outline-alt > .switch-input:checked ~ {
    .switch-label {
        background: $color-white !important;
        border-color: $color-dark;
        &::after {
            color: $color-dark;
        }
    }
    .switch-handle {
        background: $color-dark !important;
        border-color: $color-dark;
    }
}
