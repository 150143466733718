.menu-sidebar {
    width: 280px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: $color-primary;
    background: linear-gradient(180deg, $color-primary 0%, shade($color-primary, 10%) 100%);
    box-shadow: inset 0 0 30px 50px rgba(0, 0, 0, 0.04), 0 2px 8px 0 rgba(0, 0, 0, 0.35);
    overflow-y: auto;
    z-index: 5;

    .menu-sidebar__header {
    	display: flex;
    	justify-content: space-between;
    	align-items:center;
    	border-bottom: 1px solid rgba($color-white, 0.12);

    	.logo {
	        height: 60px;
	        padding: 8px 25px 5px;
	        display: -moz-box;
	        display: -ms-flexbox;
	        display: flex;
	        align-items: center;
	        position: relative;
	        z-index: 3;

            img {
                width: 120px;
            }
	    }
	    @media (max-width: 991px) {
		    .logo {
		        text-align: center;
		    }
		}

		.toggle {
			.toggle__btn {
				width: 60px;
				height: 60px;
				text-align: center;
				opacity: .7;
				i {
					margin-top: 15px;
					font-size: 2rem;
					color: $color-white;
				}
				&:hover {
					opacity: 1;
				}
			}
		}

		
    }
    
    .menu-sidebar__content {
	    position: relative;
	    height: calc(100% - 61px);
	}

	.navbar-sidebar {
		.navbar__list {
		    margin: 0;
		    list-style: none;
		    padding: 15px 0 0 0;

		    .menu__item {
			    display: flex;
			    flex-direction: column;
			    flex-grow: 1;
			    padding: 0;
			    position: relative;
			    margin: 0;
			    transition: background-color .3s;

			    a {
				    padding: 10px 25px;
				    cursor: pointer;
				    display: flex;
				    flex-grow: 1;
				    align-items: center;
				    margin: 0;
				    text-decoration: none;
				    position: relative;
				    outline: 0;
                    color: $color-white;
                    font-size: 1rem;
                    font-weight: 400;
                    transition: opacity 0.4s ease-out, transform 0.2s ease-out;
                    white-space: nowrap;
                    overflow: hidden;

                    i {
                        flex-shrink: 0;
                        margin-right: 10px;
                        color: rgba(255, 255, 255, 0.8);
                    }

                    &:hover, .menu__item--current & {
                        color: rgba(255, 255, 255, 1);
                        transform: translateX(3px);
                    }
				}

                &--has-children {
                    position: relative;

                    &::after {
                        content: "\f0da";
                        font-family: 'Font Awesome 5 Pro';
                        font-weight: 900;
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        color: white;
                        width: 30px;
                        text-align: center;
                        cursor: pointer;
                    }

                    & > ul {
                        display: none;
                    }
                }

                &--opened {
                    & > ul {
                        display: block;
                    }

                    &::after {
                        content: "\f0d7"
                    }
                }

                &--current {
                    background: rgba(0, 0, 0, 0.2);

                    & > a {
                        color: rgba(255, 255, 255, 1);
                        font-weight: bold;

                        i {
                            color: rgba(255, 255, 255, 1);
                        }


                        &:hover {
                            transform: translateX(0);
                        }
                    }

                    &:last-child {
                        margin-bottom: 5px;
                    }
                }

                &:hover {
                    a {
                        color: rgba(255, 255, 255, 1);

                        i {
                            color: rgba(255, 255, 255, 0.5);
                        }
                    }
                }

                .menu_level_1 {
                    margin: 0 20px 5px 20px;

                    .menu__item {
                        border-radius: 4px;

                        a {
                            padding: 5px 15px;
                            min-height: 0;

                            &::before {
                                content: "\f111";
                                font-family: "Font Awesome 5 Pro";
                                color: rgba($color-white, 0.5);
                                font-weight: 900;
                                font-size: 0.4rem;
                                margin-right: 10px;
                            }
                        }
                    }
                }

			    .menu__submenu {
			    	margin-left: 24px;
			    }
			}
			
			.menu__section {
			    padding: 0 25px;
                margin: 0;

			    & > span {
                    margin: 0;
                    padding: 10px 25px;
                    align-items: center;
                    font-size: 1rem;
                    text-transform: uppercase;
                    letter-spacing: .3px;
                    color: $color-primary;
                    transition: opacity 0.4s ease-out;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: bold;
                    display: block;
                    margin: 0 -25px;
                    background: $color-secondary;
				}

				&::before {
			        content: "\f141";
			        display: none;
			        font-family: 'Font Awesome 5 Pro Light';

			        .main-sidebar-condensed & {
			            display: block;
			            font-size: 1.1rem;
			        }
			    }
			}
		}
	}

	.main-sidebar-condensed & {
    	width: 60px;

    	.logo {
    		display: none;
    	}

    	.menu-sidebar__header {
			.toggle {
				.toggle__btn {
					i {
						transform: rotate(180deg);
					}
				}
			}
		}

        .navbar-sidebar {
            .menu__section > span {
                font-size: 0.7rem;
                text-align: center;
                padding: 10px 0;
            }

            .menu__item {
                ul {
                    display: none;
                }

                a {
                    padding-left: 15px;
                    padding-right: 15px;
                    justify-content: center;

                    i {
                        margin: 0;
                        font-size: 1.1rem;
                    }

                    span {
                        display: none;
                    }
                }

                &::before, &::after {
                    display: none !important;
                }
            }
        }
    }
}

.secondary-sidebar {
    width: 200px;
    border-right: 1px solid #eff0f6;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    flex: 0 0 200px;

    ul {
        margin-top: 10px;

        .menu__section {
            font-weight: 600;
            font-size: 1rem;
            color: #222;
            padding: 10px 20px;
        }

        a {
            display: block;
            padding: 10px 20px;
            margin: 0;
            text-decoration: none;
            outline: 0;
            font-size: 1rem;
            font-weight: 400;
            overflow: hidden;

            i {
                margin-right: 3px;
            }

            &:hover {
                transform: translateX(3px);
            }
        }

        .menu__item--current {
            background: #e9ecef;

            a {
                font-weight: 600;
            }
        }
    }

    .secondary-sidebar__header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        text-align: right;
        border-bottom: 1px solid #eff0f6;
        background: #fafafa;
        font-weight: 600;
        min-height: 40px;
        z-index: 2;

        .secondary-sidebar__collapse-button {
            position: absolute;
            display: flex;
            align-items: center;
            top: 0;
            bottom: 0;
            right: 0;
            padding: 0 15px 0 10px;
            transition: transform 0.2s ease-out;

            &:hover {
                text-decoration: none;
                transform: translateX(-3px);
            }

            i {
                font-size: 1.2rem;
            }
        }
    }

    .secondary-sidebar-condensed & {
        width: 50px;

        .secondary-sidebar__header {
            span {
                display: none;
            }

            a {
                padding: 0;
                left: 0;
                justify-content: center;
            }
        }

        .menu__item:not(.menu__section) {
            text-align: center;

            span {
                display: none;
            }
        }

        .menu__section {
            font-size: 0.6rem;
            letter-spacing: 0;
            text-align: center;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

