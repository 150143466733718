html.l-hub {
    &, body {
        font-family: Arial, sans-serif;
        background: #351792;
        height: 100%;
        color: #fff;
    }

    #main-container {
        padding-top: 5%;
    }

    h1 {
        color: inherit;
        text-align: center;
        margin-bottom: 2rem;

        span {
            margin-top: 0.4rem;
            font-size: 1.7rem;
            display: block;
        }
    }

    p {
        font-size: 1.2rem;
    }

    a {
        color: white;

        &:hover {
            text-decoration: underline;
        }
    }

    #main-logo {
        width: 8rem;
        margin-bottom: 1.5rem;
    }

    .app-item {
        display: block;
        background: #fff;
        border-radius: 4px;
        padding: 1.2rem;
        min-width: 20rem;
        max-width: 20rem;
        margin: 0.5rem;
        transition: transform 0.15s ease-in;

        img {
            width: 100%;
            object-fit: cover;
        }

        span {
            display: block;
            text-align: center;
            font-weight: bold;
            color: #1c1c1c;
        }

        img + span {
            margin-top: 0.6rem;
        }

        &:hover {
            cursor: pointer;
            transform: scale(1.03);
            transition: transform 0.15s ease-out;
            box-shadow: #8846ff 0 0 12px;
        }
    }
}
