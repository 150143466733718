//
// Mettre ici les variables bootstrap a modifier.
//
$theme-colors: (
    "primary":    $color-primary,
    "secondary":  $color-secondary,
    "success":    $color-success,
    "info":       $color-info,
    "warning":    $color-warning,
    "danger":     $color-danger,
    "light":      $color-light,
    "dark":       $color-dark
);

$spacer: 5px !default;
$spacers:
  (
    0: 0,
    1: ($spacer * 1),
    2: ($spacer * 2),
    3: ($spacer * 3),
    4: ($spacer * 4),
    5: ($spacer * 5),
    6: ($spacer * 6),
    7: ($spacer * 7),
    10: ($spacer * 10)
);

$link-color: $color-primary;
