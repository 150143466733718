.vc-panel-loader {
    text-align: center;
    padding: 15px 30px;
    background: #f6f6f6;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vc-panel-loader > div {
    position: relative;
    padding-top: 10px;
}
