.fr-visibility-helper {
  display: none;
  margin-left: 0 !important;

  @media (min-width: $screen-sm) {
    margin-left: 1px !important;
  }

  @media (min-width: $screen-md) {
    margin-left: 2px !important;
  }

  @media (min-width: $screen-lg) {
    margin-left: 3px !important;
  }
}

.fr-opacity-0 {
  @include opacity(0);
}