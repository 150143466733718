&, body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-primary;
    color: $color-primary-invert;
}

#error-body {
    margin: auto;
    padding: 20px;
    text-align: left;
    position: relative;
    padding-right: 190px;

    & > i {
        color: rgba(255, 255, 255, 0.04);
        position: absolute;
        z-index: 0;
        font-size: 15rem;
        top: 0;
        right: 0;
    }

    //&::before {
    //    font-family: "Font Awesome 5 Pro";
    //    font-weight: 900;
    //    -moz-osx-font-smoothing: grayscale;
    //    -webkit-font-smoothing: antialiased;
    //    display: inline-block;
    //    font-style: normal;
    //    font-variant: normal;
    //    text-rendering: auto;
    //    line-height: 1;
    //    content: "\f5b3";
    //    color: rgba(255, 255, 255, 0.04);
    //    position: absolute;
    //    z-index: 0;
    //    font-size: 15rem;
    //    top: 0;
    //    right: 0;
    //}
}


h1 {
    font-size: 72px;
    color: tint($color-primary, 35%);
    opacity: 0.9;
}

h3 {
    font-size: 48px;
    line-height: 32px;
    text-transform: uppercase;
    font-weight: 300;
    color: tint($color-primary, 35%);
    opacity: 0.9;
}

hr {
    width: 100px;
    margin: 0.5em 0 1em;
    border: none;
    height: 2px;
    background: rgba(255, 255, 255, 0.4);
}

p {
    font-size: 24px;
    line-height: 34px;
    font-style: italic;
    font-weight: 300;
}

a {
    color: $color-primary-invert;
    text-decoration: underline;

    &:hover {
        color: shade($color-primary-invert, 25%);
    }
}
