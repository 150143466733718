.collapsible-panel, .cp {
    margin-bottom: 10px;
}

.collapsible-panel__header, .cp__header {
    // background: #595d6e;
    background: $color-primary;
    padding: 7px 12px;
    font-weight: 600;
    color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }

    a {
        color: #fff;
    }
}

.collapsible-panel__body, .cp__body {
    padding: 10px;
    border: 1px solid #e7e8ef;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;
    display: none;
}

.collapsible-panel__floaters, .cp__floaters {
    position: absolute;
    right: 10px;
    top: 0;
    display: flex;

    & > * {
        color: #fff;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 3px 10px;
        // background: #595d6e;
        background: $color-primary;
        font-size: 0.8rem;
        margin-right: 10px;

        a {
            color: #fff;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.collapsible-panel--opened, .cp--opened {
    .collapsible-panel__body, .cp__body {
        display: block;
    }

    .collapsible-panel__header, .cp__header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

