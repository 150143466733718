@import '../variables.scss';
@import '../mixins.scss';

textarea.fr-code {
  display: none;
  width: 100%;
  @include resize(none);
  @include box-sizing(border-box);
  border: none;
  padding: 10px;
  margin: 0;
  font-family: "Courier New",monospace;
  font-size: 14px;
  background: $code-view-bg;
  color: $code-view-text;
  outline: none;
}

.fr-box.fr-rtl {
  textarea.fr-code {
    direction: rtl;
  }
}

.fr-box .CodeMirror {
  display: none;
}

.fr-box.fr-code-view {
  textarea.fr-code {
    display: block;
  }

  .fr-element, .fr-placeholder, .fr-iframe {
    display: none;
  }

  .CodeMirror {
    display: block;
  }
}

.fr-box.fr-inline {
  &.fr-code-view .fr-command.fr-btn.html-switch {
    display: block;
  }

  .fr-command.fr-btn.html-switch {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    background: $white;
    color: $btn-text;
  	-moz-outline: 0;
  	outline: 0;
    border: 0;
    line-height: 1;
  	cursor: pointer;
    text-align: left;
    padding: (($btn-height - $btn-font-size) / 2) (($btn-width - $btn-font-size) / 2);
    @include transition(background $transition-timing);
    @include border-radius(0);
    z-index: 2;
    @include box-sizing(border-box);
    text-decoration: none;
    @include user-select(none);

    i {
      font-size: $btn-font-size;
      width: $btn-font-size;
      text-align: center;
    }

    &.fr-desktop:hover {
      background: $btn-hover-bg;
    }
  }
}
