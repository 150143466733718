/**
 * Source: https://tobiasahlin.com/spinkit/
 */
.vc-ellipsis-loader {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vc-ellipsis-loader > div {
    width: 14px;
    height: 14px;
    background-color: $color-primary;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.vc-ellipsis-loader .bounce1 {
    animation-delay: -0.32s;
}

.vc-ellipsis-loader .bounce2 {
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}

.vc-ellipsis-loader.lg {
    width: 70px;

    & > div {
        width: 20px;
        height: 20px;
    }
}

.vc-ellipsis-loader.sm {
    width: 35px;

    & > div {
        width: 8px;
        height: 8px;
    }
}

.vc-ellipsis-loader.xs {
    width: 20px;

    & > div {
        width: 4px;
        height: 4px;
    }
}
