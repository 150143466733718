.text-muted {
    color: #a4a8ad !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.f-05 {
    font-size: 0.5rem !important;
}

.f-06 {
    font-size: 0.6rem !important;
}

.f-07 {
    font-size: 0.7rem !important;
}

.f-08 {
    font-size: 0.8rem !important;
}

.f-09 {
    font-size: 0.9rem !important;
}

.f-1 {
    font-size: 1rem !important;
}

.f-11 {
    font-size: 1.1rem !important;
}

.f-12 {
    font-size: 1.2rem !important;
}

.f-13 {
    font-size: 1.3rem !important;
}

.f-14 {
    font-size: 1.4rem !important;
}

.f-15 {
    font-size: 1.5rem !important;
}

.f-16 {
    font-size: 1.6rem !important;
}

.f-17 {
    font-size: 1.7rem !important;
}

.f-18 {
    font-size: 1.8rem !important;
}

.f-19 {
    font-size: 1.9rem !important;
}

.f-2 {
    font-size: 2rem !important;
}

.f-21 {
    font-size: 2.1rem !important;
}

.f-22 {
    font-size: 2.2rem !important;
}

.f-23 {
    font-size: 2.3rem !important;
}

.f-24 {
    font-size: 2.4rem !important;
}

.f-25 {
    font-size: 2.5rem !important;
}

.f-26 {
    font-size: 2.6rem !important;
}

.f-27 {
    font-size: 2.7rem !important;
}

.f-28 {
    font-size: 2.8rem !important;
}

.f-29 {
    font-size: 2.9rem !important;
}

.f-3 {
    font-size: 3rem !important;
}

.f-32 {
    font-size: 3.2rem !important;
}

.f-35 {
    font-size: 3.5rem !important;
}

.f-4 {
    font-size: 4rem !important;
}

.f-45 {
    font-size: 4.5rem !important;
}

.f-5 {
    font-size: 5rem !important;
}

