.vc-login-page-form {
    .vue-codemirror {
        border: 1px solid #eff0f6;
        border-radius: 4px;
        overflow: hidden;
    }

    .CodeMirror {
        height: 500px;
    }
}
