/*!
 * froala_editor v3.0.6 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2019 Froala Labs
 */

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-box .fr-counter {
  padding: 10px;
  float: right;
  color: #999999;
  content: attr(data-chars);
  font-size: 14px;
  font-family: sans-serif;
  z-index: 1;
  border-radius: 2px 0 0 0;
  -moz-border-radius: 2px 0 0 0;
  -webkit-border-radius: 2px 0 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }
.fr-box.fr-rtl .fr-counter {
  left: 0;
  right: auto;
  border-left: none;
  border-radius: 0 2px 0 0;
  -moz-border-radius: 0 2px 0 0;
  -webkit-border-radius: 0 2px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box; }

.fr-box.fr-code-view .fr-counter {
  display: none; }
