.table {
    color: $color-grey-dark1;
    background-color:$color-white;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: none;
    border-top: none;
    background-color: $color-primary;
    color: $color-white;
}
.table th, .table td {
    padding: 0.7rem 0.75rem;
    vertical-align: top;
    border-top: 1px solid $color-grey-medium;
}

.table a {
    // color: #595d6e;
    color: $color-primary;
    font-weight: 600;
}


