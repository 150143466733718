/*!
 * froala_editor v3.0.6 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2019 Froala Labs
 */

.clearfix::after {
  clear: both;
  display: block;
  content: "";
  height: 0; }

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.fr-popup .fr-layer.fr-color-hex-layer {
  margin: 0;
  padding: 20px;
  padding-top: 0;
  float: left; }
  .fr-popup .fr-layer.fr-color-hex-layer .fr-input-line {
    float: left;
    width: calc(100% - 50px);
    padding: 15px 0 0; }
  .fr-popup .fr-layer.fr-color-hex-layer .fr-action-buttons {
    float: right;
    width: 38px;
    height: 40px;
    padding: 17px 0 0;
    margin: 0; }
  .fr-popup .fr-layer.fr-color-hex-layer .fr-action-buttons button.fr-command {
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    font-size: 13px;
    height: 40px;
    width: 38px; }
.fr-popup .fr-separator + .fr-colors-tabs {
  margin-left: 2px;
  margin-right: 2px; }
.fr-popup .fr-color-set {
  line-height: 0;
  display: none; }
  .fr-popup .fr-color-set.fr-selected-set {
    display: block;
    padding: 20px;
    padding-bottom: 0; }
  .fr-popup .fr-color-set > span {
    display: inline-block;
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 1; }
    .fr-popup .fr-color-set > span > i, .fr-popup .fr-color-set > span > svg {
      text-align: center;
      line-height: 32px;
      height: 24px;
      width: 24px;
      margin: 4px;
      font-size: 13px;
      position: absolute;
      bottom: 0;
      cursor: default;
      left: 0; }
      .fr-popup .fr-color-set > span > i path, .fr-popup .fr-color-set > span > svg path {
        fill: #222222; }
    .fr-popup .fr-color-set > span .fr-selected-color {
      color: #FFF;
      font-family: FontAwesome;
      font-size: 13px;
      font-weight: 400;
      line-height: 32px;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      text-align: center;
      cursor: default; }
    .fr-popup .fr-color-set > span:hover, .fr-popup .fr-color-set > span:focus {
      outline: 1px solid #222222;
      z-index: 2; }

.fr-rtl .fr-popup .fr-colors-tabs .fr-colors-tab.fr-selected-tab[data-param1="text"] ~ [data-param1="background"]::after {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  -ms-transform: translate3d(100%, 0, 0);
  -o-transform: translate3d(100%, 0, 0); }
