.breadcrumb {
  background-color: transparent;
  padding:0;
  margin: 0;
  
  .breadcrumb-item {
      padding: 0 .35rem 0 0;
      font-size: 1rem;
      font-weight: 500;
      color: $color-grey;

      a {
          color: $color-grey;
          display: inline-block;
          vertical-align: middle;
          &:hover {
            color: $color-grey-dark1;
            text-decoration: none;
          }
        }

      &.active {
        margin: 0;
        padding: 0 1rem 0 0;
        font-size: 1.1rem;
        font-weight: 600;
        color: $color-tertiary-dark;
    }

    & + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: $color-grey-medium;
        content: "•";
    }

  }

}

