//
// Input component
//
// Adler-32 hash of the file path of the component.ts file from the "scripts" folder.
// http://oss.sheetjs.com/js-adler32/
//
// Change the hash if you change the location of the file.
.vc-830347552 {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 4px 6px;
    color: #555;
    vertical-align: middle;
    max-width: 100%;
    line-height: 22px;
    cursor: text;
    display: block;

    .tag {
        margin-right: 4px;
        color: white;

        &.label {
            display: inline;
            padding: .2em .6em .3em;
            font-size: 75%;
            font-weight: 700;
            line-height: 1;
            color: #fff;
            text-align: center;
            white-space: nowrap;
            vertical-align: baseline;
            border-radius: .25em;
        }

        [data-role="remove"] {
            margin-left: 8px;
            cursor: pointer;

            &::after {
                content: "x";
                padding: 0 2px;
            }
        }
    }

    input {
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        padding: 0 6px;
        margin: 0;
        width: auto;
        max-width: inherit;
    }
}


