.vc-panel-error {
    text-align: center;
    padding: 15px 30px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vc-panel-error__title {
    font-size: 2rem;
    font-weight: 600;
}

.vc-panel-error__icon {
    font-size: 3rem;
}
