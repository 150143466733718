.vc-list__column-filter {
    vertical-align: middle !important;
}

.vc-list__column-filter__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;

    .form-group {
        flex: 1;
        margin: 0;
    }
}
