
.form-section {
    padding: 25px;

    .card-body & {
        margin: -25px;
    }

    .vc-collapsible-panel__body & {
        margin: 0;
        padding: 0;

        .form-section__title {
            margin: -10px -10px 10px;
        }
    }

    & + .form-section {
        margin-top: 15px;
    }
}

.form-section__title {
    background: #f6f7fa;
    margin: -25px -25px 10px -25px;
    padding: 8px 15px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    border-bottom: 1px solid #eff0f6;
    display: flex;
    justify-content: space-between;
}
