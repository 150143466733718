@mixin resize($val) {
  resize: $val;
  -moz-resize: $val;
  -webkit-resize: $val;
}

@mixin opacity($opacity: 0.5) {
	-webkit-opacity: 	$opacity;
	-moz-opacity: 		$opacity;
	opacity: 		$opacity;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

@mixin transition($transition...) {
	-webkit-transition: $transition;
	-moz-transition:    $transition;
	-ms-transition:     $transition;
	-o-transition:      $transition;
}

@mixin transform($string) {
	-webkit-transform: $string;
	-moz-transform: 	 $string;
	-ms-transform: 		 $string;
	-o-transform: 		 $string;
}

@mixin box-sizing($type: border-box) {
	-webkit-box-sizing: $type;
	-moz-box-sizing:    $type;
	box-sizing:         $type;
}

@mixin border-radius($radius: 0) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;

  -moz-background-clip:    padding;
	-webkit-background-clip: padding-box;
	background-clip:         padding-box;
}

@mixin user-select($select) {
  user-select: $select;
  -o-user-select:$select;
  -moz-user-select: $select;
  -khtml-user-select: $select;
  -webkit-user-select: $select;
  -ms-user-select: $select;
}

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

.clearfix {
  &::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }
}

@mixin clearfix {
  &::after {
    clear: both;
    display: block;
    content: "";
    height: 0;
  }
}

@mixin column-count($count) {
  column-count: $count;
  -moz-column-count: $count;
  -webkit-column-count: $count;
}

@mixin column-gap($gap) {
  column-gap: $gap;
  -moz-column-gap: $gap;
  -webkit-column-gap: $gap;
}

@mixin animation($val) {
	-webkit-animation: $val;
  -moz-animation: $val;
  -o-animation: $val;
  animation: $val;
}

@mixin handler-size($size) {
  .fr-handler {
    width: $size;
    height: $size;

    &.fr-hnw {
      left: (-$size / 2);
      top: (-$size / 2);
    }

    &.fr-hne {
      right: (-$size / 2);
      top: (-$size / 2);
    }

    &.fr-hsw {
      left: (-$size / 2);
      bottom: (-$size / 2);
    }

    &.fr-hse {
      right: (-$size / 2);
      bottom: (-$size / 2);
    }
  }
}

@mixin font-smoothing() {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

@mixin hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

@mixin display-inline-flex() {

  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }  
  @-o-keyframes #{$animation-name} {
    @content;
  }  
  @keyframes #{$animation-name} {
    @content;
  }
}
