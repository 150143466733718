.vc-form-select-native {
    label {
        padding-top: 5px;
    }

    .vc-form-select-native__select-wrapper {
        position: relative;
    }

    .vc-form-select-native__loader {
        position: absolute;
        top: 0;
        background: #f1f1f1;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 3px;
        padding: 5px 15px;
        display: flex;
        align-items: center;
    }
}
