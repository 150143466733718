.vc-user-access-rights-manager {
    .form-group {
        margin-bottom: 0;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #000000;
        background-color: #222222;
    }

    .cp__header {
        cursor: initial;

        .custom-control-label {
            color: #fff;
        }
    }

    .application__body {
        padding: 0;
    }

    .application__actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        color: $color-white;

        & > * {
            margin-left: 10px;
        }
    }

    .module__header {
        background: #f1f1f1;
        padding: 5px 10px;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }


    .module__body {
        padding: 8px 15px;
    }

    .feature .custom-control-label span {
        font-style: italic;
        font-size: 0.9rem;
        color: #8c8c8c;
    }

    .feature {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .feature__expiration-date {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        span {
            margin-right: 10px;
            font-style: italic;
            font-size: 0.9rem;
            color: #8c8c8c;
        }
    }

    .popover-container:hover {
        cursor: pointer;
    }
}
