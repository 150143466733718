.vc-form-file {
    label {
        padding-top: 5px;
    }

    &__wrapper {
        flex: 1;
        //border: 1px solid #ced4da;
        //border-radius: 0.25rem;
        overflow: hidden;
    }

    .dz-clickable {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: row;
        flex: 1;
    }

    &__upload-progress {
        background: #e2e2e2;
        border-radius: 2px;
    }

    &__upload-progress-bar {
        height: 5px;
        border-radius: 2px;
        background: $color-primary;
    }

    &__cancel-upload-link {
        color: $color-danger;
        text-decoration: underline;
    }

    &__view-link {
        text-decoration: underline;
    }

    &__error-messages {
        color: $color-danger;
        margin: 0 0 0 25px;
        list-style-type: disc;

        span {
            position: relative;
            left: -4px;
        }
    }
}

/**
 * Single file upload
 */
.vc-form-file-single {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    &--uploading, &__button {
        border-bottom-right-radius: 0;
    }

    &__upload {
        position: relative;
        border-top: 1px solid #ced4da;
        padding: 5px 10px;

        .filename {
            padding-bottom: 2px;
        }

        &--error {
            background: rgba($color-danger, 0.2);

            &, a {
                color: $color-danger;
            }

            .filename {
                i {
                    margin-right: 3px;
                }
            }
        }
    }

    &__inner {
        display: flex;
        align-items: center;
    }

    &__input {
        flex: 1;
    }

    &__filename {
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }

    &__close-errors-link {
        position: absolute;
        right: 10px;
        top: 4px;
    }

    &__button {
        min-height: 0;
        padding: 0.35rem 0.8rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &__trash-button {
        border-radius: 0;
        color: #717d86;
    }

    &__cancel-button i {
        margin-right: 3px;
    }
}

/**
 * Multiple
 */
.vc-form-file-multiple {
    padding: 15px 15px 30px 15px;
    position: relative;
    min-height: 120px;
    cursor: pointer;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;

    &__drop-invitation {
        padding: 15px 20px;
        text-align: center;
        color: #a5a5a5;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 3px;
        font-weight: 600;
        font-size: 1.1rem;
        z-index: 0;
        pointer-events: none;
        display: none;

        span {
            font-size: 1rem;
            font-weight: normal;
            display: block;
        }
    }

    &__click-invitation {
        color: #a5a5a5;
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1rem;
        z-index: 0;
        pointer-events: none;
        display: none;
    }

    &--empty &__drop-invitation {
        display: block;
    }

    &:not(.vc-form-file-multiple--empty) &__click-invitation {
        display: block;
    }

    &__section-title {
        font-weight: 600;
        padding-bottom: 3px;
        margin-bottom: 5px;
        font-size: 1.05rem;
    }

    &__form-files {

    }

    &__form-file {
        display: inline-block;
    }

    &__form-files + &__uploading-files {
        margin-top: 20px;
    }

    &__uploading-files {

    }

    &__uploading-file {
        position: relative;
        margin: 0 -15px 10px -15px;
        padding: 0 15px 10px 15px;

        &--error {
            padding-top: 10px;
            background: rgba($color-danger, 0.2);

            &, a {
                color: $color-danger;
            }

            .filename {
                i {
                    margin-right: 3px;
                }
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid #ced4da;
        }

        .vc-form-file__upload-progress {
            margin-top: 5px;
        }
    }

    &__close-errors-link {
        color: $color-danger;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 5%;
        max-width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
