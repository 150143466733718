.vc-list__button {
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
    background: rgb(89, 93, 110);
    color: white;
    font-weight: 500;

    &:hover {
        background: rgb(72, 75, 91);
    }

    &.btn-danger {
        background-color:  $color-danger;
        color:  $color-danger-invert;
    }

    &.btn-success {
        background-color:  $color-success;
        color:  $color-success-invert;
    }

    &.btn-info {
        background-color:  $color-info;
        color:  $color-info-invert;
    }

}
