/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Options
 * For quickly enabling or disabling optional features.
 * ---------------------------------------------------------------------------------------------------------------------
 */
$enable-caret               : true !default;
$enable-rounded             : true !default;
$enable-shadows             : false !default;
$enable-gradients           : false !default;
$enable-transitions         : true !default;
$enable-print-styles        : true !default;

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Colors
 * ---------------------------------------------------------------------------------------------------------------------
 */
// Main colors
$color-primary              : rgb(60, 95, 244) !default;
$color-primary-dark         : rgb(38, 64, 150) !default;
$color-primary-light         : rgb(75, 114, 255) !default;
$color-primary-invert       : rgb(255, 255, 255) !default;

$color-secondary            : rgb(227, 227, 93) !default;
$color-secondary-invert     : rgb(255, 255, 255) !default;
$color-secondary-dark       : rgb(165, 165, 70) !default;

$color-tertiary             : rgb(100, 108, 154) !default;
$color-tertiary-light       : rgb(239, 240, 246) !default;
$color-tertiary-dark       : rgb(75, 81, 116) !default;

$color-tertiary-invert      : rgb(255, 255, 255) !default;
$color-quaternary           : rgb(0, 0, 0) !default;
$color-quaternary-invert    : rgb(255, 255, 255) !default;
$color-quinary              : rgb(0, 0, 0) !default;
$color-quinary-invert       : rgb(255, 255, 255) !default;

// States colors
$color-success              : rgb(92, 184, 92) !default;
$color-success-invert       : rgb(255, 255, 255) !default;
$color-info                 : rgb(3, 169, 244) !default;
$color-info-invert          : rgb(255, 255, 255) !default;
$color-warning              : rgb(255, 193, 7) !default;
$color-warning-invert       : rgb(111, 83, 0) !default;
$color-danger               : rgb(200, 40, 40) !default;
$color-danger-invert        : rgb(255, 255, 255) !default;
$color-muted                : rgb(153, 153, 153)  !default;
$color-muted-invert         : rgb(90, 90, 90)  !default;

$color-light                : rgb(255, 255, 255)  !default;
$color-dark                : rgb(0, 0, 0)  !default;

// Color names
$color-white                : rgb(255, 255, 255)  !default;
$color-grey-light           : rgb(246, 246, 246)  !default;
$color-grey-medium           : rgb(231, 232, 239)  !default;
$color-grey                  : rgb(149, 156, 182)  !default;
$color-grey-dark1           : rgb(116, 120, 141)  !default;
$color-grey-dark2           : rgb(89, 93, 110)  !default;
$color-grey-dark3           : rgb(72, 75, 91)  !default;
$color-black                : rgb(0, 0, 0)  !default;

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Typography
 * ---------------------------------------------------------------------------------------------------------------------
 */
$font-family-sans-serif     : -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace      : SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base           : $font-family-sans-serif !default;
$font-size-base-min         : 12px !default; // MUST be in px
$font-size-base-max         : 16px !default; // MUST be in px

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Sass MQ variables
 * https://github.com/sass-mq/sass-mq
 * ---------------------------------------------------------------------------------------------------------------------
 */
// To enable support for browsers that do not support @media queries,
// (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
// Create a separate stylesheet served exclusively to these browsers,
// meaning @media queries will be rasterized, relying on the cascade itself
$mq-responsive: true !default;

// Name your breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers.
$mq-breakpoints: (
    'mobile':  320px,
    'tablet':  768px,
    'desktop': 991px,
    'wide':    1300px,

    // Tweakpoints
    'mobileLandscape': 480px,
) !default;

// Define the breakpoint from the $mq-breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (when $mq-responsive is set to 'false').
$mq-static-breakpoint: desktop !default;

// If you want to display the currently active breakpoint in the top
// right corner of your site during development, add the breakpoints
// to this list, ordered by width. For example: (mobile, tablet, desktop).
//
// We cannot set a default value to "$mq-show-breakpoints" here because we cannot override
// it with an empty list for production builds (sass consider the value as not set and use the one defined here instead).
//
// So if you want to show breakpoints in your app, copy/paste the line below in the _variables.scss file of your app:
// $mq-show-breakpoints: (mobile, mobileLandscape, tablet, desktop, wide) !default;
$mq-show-breakpoints: () !default;

/**
 * Z-indexes
 */
$z-indexes: (
    'below': -1,
    'default': 1,
    'position-fixed': 1000,
    'position-sticky': 1010,
    'modal': 5000,
) !default;

/**
 * ---------------------------------------------------------------------------------------------------------------------
 * Define the default min and max width the "responsive-size" mixin is
 * based on when no size is defined when including the mixin.
 *
 * The size will be at the lowest value when the screen size is <= at $responsive-size-min-width
 * and at the highest value when the screen size is >= at $responsive-size-max-width.
 * ---------------------------------------------------------------------------------------------------------------------
 */
$responsive-size-min-width: 320px !default;
$responsive-size-max-width: 700px !default;
