// Surcharges boutons bootstrap

.btn {
	display: inline-block;
    font-weight: 400;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: all .15s ease-in-out;

    &:hover {
        border-color:transparent;
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus {
        background: #d4d4d4 !important;
        border-color: #d4d4d4 !important;
        transform: none !important;
        color: #909090 !important;
        cursor: not-allowed;
    }
}

.btn-sm {
    font-size: .875rem;
    line-height: 0.9rem;
    border-radius: .2rem;
}

.btn-xs {
    font-size: 0.7rem;
    line-height: 0.6rem;
    border-radius: 0.15rem;
}

.btn-bold, .btn-bolder {
    font-weight: 600;
}

.btn-label-brand {
    background: $color-grey-medium;
    color: $color-grey-dark1;

    &:hover {
        background-color:$color-grey;
        color: $color-white;
    }
}

.btn-primary {
    background: $color-primary !important;
    color: $color-white !important;
    font-weight: 600 !important;

    &:hover {
    	background-color: $color-primary-dark !important;
    }
}

.btn-secondary {
    background: $color-secondary !important;
    color: $color-white !important;
    font-weight: 600 !important;

    &:hover {
        background-color:$color-secondary-dark !important;
    }
}

.btn-light {
    background: #f6f6f6 !important;
    border: 1px solid #ececec;
}

.btn-white {
    background: #fff !important;
}

.btn-edit {
    display: inline-block;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
    border-radius: .2rem;
    i {
        color: $color-grey;
        font-size: 1.2rem;
    }
    &:hover {
        color: $color-grey-dark1;
        background: $color-grey-medium;
        i {
          color: $color-grey-dark1;
        }
    }
}

.btn:hover {
    transform: scale(1.05);
}
