.alertify-notifier {
    .ajs-message {
        width: 350px;
        text-shadow: none !important;
        border-radius: 4px !important;
        border: none !important;
        font-weight: 500;

        &.ajs-visible {
            transition-duration: 200ms !important;
            transition-timing-function: ease-out !important;
        }
    }

    .ajs-message.ajs-warning {
        background: $color-warning;
        color: shade($color-warning, 70%);
    }

    .ajs-message.ajs-info {
        background: $color-info;
        color: #fff;
    }

    .ajs-message a {
        color: #fff;
        font-weight: bold;
        text-decoration: underline;
    }
}
