* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-size: 13px;
}

body {
    font-family: $font-family-base;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.625;
    color: $color-tertiary;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    color: $color-tertiary;
    font-weight: 700;
    margin: 0;
    line-height: 1.2;
}

a {
    display: inline-block;
    transition: all 0.3s ease;
    color: #4b5177;

    &:hover, &:focus, &:active {
        text-decoration: none;
        outline: none;
    }
    &:hover {
        transition: all 0.3s ease;
    }
}
