.vc-tree-item .vc-tree-item {
    margin-left: 20px;

    &.vc-tree-item--has-children {
        margin-left: 30px;
    }
}

.vc-tree-item__content {
    display: flex;
    border-bottom: 1px solid #f2f2f2;
    padding: 5px 0;
    margin-left: 18px;
    align-items: center;
    justify-content: space-between;
    transition: padding 0.2s ease-in-out;

    .toggle-btn i {
        margin-left: -18px;
        transition: margin 0.2s ease-in-out;
    }

    &:hover {
        background: #f3f3f3;
        padding: 5px 10px;
        border-radius: 3px;

        .switch-btn {
            opacity: 1;
        }

        .toggle-btn i {
            margin-left: -28px;
        }
    }
}

.vc-tree > .vc-tree-item:last-child > .vc-tree-item__content {
    border: none;
}

.vc-tree-item__left-content, .vc-tree-item__right-content {
    display: flex;

    .form-group {
        margin: 0;
    }
}

.vc-tree-item--has-children:not(.vc-tree-item--interactive-content) {
    &.vc-tree-item--opened > .vc-tree-item__content:hover {
        cursor: n-resize;
    }

    &:not(.vc-tree-item--opened) > .vc-tree-item__content:hover {
        cursor: s-resize;
    }
}
