//
// Input component
//
// Adler-32 hash of the file path of the component.ts file from the "scripts" folder.
// http://oss.sheetjs.com/js-adler32/
//
// Change the hash if you change the location of the file.
.vc-996511d8 {
    input {
        background: red;
    }
}
