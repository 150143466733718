
.vc-list__pagination {
    display: flex;

    .pagination {
        margin: 0;

        .page-link {
            color: #595d72;
            box-shadow: none;
            transition: all 0.2s ease-in-out;
        }

        .page-link--current {
            background: rgb(89, 93, 110);
            color: white;
        }
    }

    .page-number-input {
        border: none;
        padding: 0;

        input {
            border-radius: 0;
            border-left: 0;
            border-color: rgb(89, 93, 110);
            background: rgb(89, 93, 110);
            color: white;
            font-weight: 600;
            width: 50px;
            outline: none !important;
            box-shadow: none;
            text-align: center;
        }
    }

    .page-item:first-child .page-number-input input {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }

    .page-item:last-child .page-number-input input {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    .items-per-page {
        width: 70px;
        margin-left: 5px;
        box-shadow: none;
    }
}
