#p-login {
    h1 {
        font-size: 1.8rem;
    }

    &.back {
        background: #F1F1F1;
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
    }

    input {
        outline: none;
    }

    input[type='checkbox'] {
        outline: none !important;
    }

    label {
        margin: 0 !important;
        display: flex;
        align-items: center;
    }

    label img {
        width: 16px;
        margin-right: 5px;
    }

    .login-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        margin: auto;
    }

    .right-panel {
        position: relative;
        background: $color-primary;
        width: 10px;
        border-radius: 0 10px 10px 0;
    }

    .login {
        position: relative;
        background: white;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 500px;
        border-radius: 10px 0 0 10px;
        box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
        padding: 50px;

    }


    @media screen and (min-width: 501px) and (max-width: 900px) {
        .login {
            width: 450px;
        }

        .login-title:before {
            left: 135px !important;
        }
    }

    @media screen and (max-width: 500px) {
        .login {
            width: 300px;
        }

        .login-title h1 {
            font-size: 1.5rem;
        }
    }

    .login-title {
        top: 0;
        margin: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        color: $color-primary;
        font-size: 1.2rem;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .login-title h1 {
        text-transform: uppercase;
        margin: 0;
    }

    .login-title p {
        margin: 0;
        color: #8f8f8f;
        font-size: 1.05rem;
    }

    .login-form form {
        /*padding: 20px 30px 30px 30px;*/
        display: flex;
        flex-direction: column;
    }

    .login-form a {
        text-align: right;
        text-decoration: none;
        font-weight: 400;
    }

    .login-form a:hover {
        color: rgba($color-primary, 0.7);
    }


    .form-text {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin: 5px 0;

    }

    .form-submit {
        padding: 12px 25px;
        width: 100%;
        margin-top: 20px;
        border-radius: 3px;
        background: $color-primary;
        border: none;
        font-size: 1rem;
        color: white;
        font-weight: 600;
        height: 46px;
        cursor: pointer;
        text-transform: uppercase;
    }

    .form-submit:hover {
        background: rgba($color-primary, 0.8);
    }


    .form-group {
        width: 100%;
        text-transform: uppercase;
        margin-top: 5px;

        &:first-child {
            margin-top: 30px;
        }
    }

    .form-group input {
        background-color: #F1F1F1;
        width: calc(100% - 40px);
        height: 22px;
        border: 0;
        border-radius: 4px;
        box-sizing: content-box;
        padding: 12px 20px;
    }

    .error {
        margin-top: 20px;
        background: $color-danger;
        padding: 5px 15px;
        border-radius: 3px;
        color: #fff;
        text-align: center;
    }
}
