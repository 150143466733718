@import 'contrast.scss';
// Theme Name.
$theme: 'theme' !default;

// Generic.
$white: #FFF !default;
$light-black: #333333 !default;
$black: #000 !default;
$gray: #CCCCCC !default;
$dark-grey: #999999 !default;
$font-family: Arial, Helvetica, sans-serif !default;
$border-radius: 2px !default;
$transition-timing: 0.2s ease 0s !default;
$bg-color: #f5f5f5 !default;

// UI colors
$ui-color: #0098f7 !default;
$ui-text: #222222 !default;
$ui-hover-light-color: mix($white, #999, 90%) !default;
$ui-hover-color: mix($white, #999, 80%) !default;
$ui-focused-color: mix($white, #999, 60%) !default;
$ui-disabled-color: mix($white, #000, 74%) !default;
$ui-active-color: #d6d6d6 !default;
$ui-bg: $white !default;
$ui-font-size: 14px !default;
$ui-border-color: $ui-text !default;
$ui-border-top: 5px solid $ui-border-color !default;
$ui-border-hover-color:#515151 !default;
$ui-bg-color: #efefef !default;
$ui-svg-size: 24px !default;
$ui-box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12) !default;
$ui-bg-color-more: $bg-color !default;

// Separator
$separator-size: 1px !default;
$separator-color: mix($white, #999, 80%) !default;
$separator-margin: 8px 4px !default;

// Toolbar
$toolbar-transition-timing: 0.5s !default;
$toolbar-newline-margin: 9px !default;
$toolbar-newline-height: 1px !default;
$toolbar-newline-color: $ui-bg-color !default;
$btn-more-open-margin-top: 10px !default;
$btn-more-open-margin-bottom: -1px !default;
$btn-size: 36px !default;

// Logo
$logo-svg-height: 20px !default;
$logo-svg-width: 47px !default;
$logo-svg-margin: 7px 0 !default;
$logo-transition-timing: 0.3s !default;
$logo-span-padding: 11px 5px 10px 15px !default;
$logo-span-font-size: 12px !default;
$logo-color: #b1b2b7 !default;

// Screen sizes.
$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;
$screen-xs-max: ($screen-sm - 1) !default;
$screen-sm-max: ($screen-md - 1) !default;
$screen-md-max: ($screen-lg - 1) !default;
$screen-lg-max: 'auto' !default;

// Tooltip
$tooltip-bg: $ui-text !default;
$tooltip-text: $white !default;
$tooltip-font-size: 11px !default;
$tooltip-line-height: 22px !default;

// Editor properties.
$editor-padding: 20px !default;
$editor-bg: $white !default;
$editor-font-family: sans-serif !default;
$editor-font-color: #414141 !default;
$editor-font-size: 14px !default;
$editor-line-height: 1.6 !default;
$editor-shadow-level: 1 !default;
$editor-border: 1px solid $gray !default;
$editor-border-radius: 10px !default;
$editor-align: left !default;

// Text selection colors.
$selection-bg: #b5d6fd !default;
$selection-text: #000 !default;

// Placeholder properties.
$placeholder-size: $editor-font-size !default;
$placeholder-font-family: $editor-font-family !default;
$placeholder-color: #AAA !default;

// Button colors.
$btn-text: $light-black !default;
$btn-hover-text: $light-black !default;
$btn-hover-bg: #ebebeb !default;

$btn-active-text: $ui-color !default;
$btn-active-bg: $ui-active-color !default;
$btn-active-hover-text: $btn-active-text !default;

$btn-selected-text: $btn-text !default;
$btn-selected-bg: $ui-focused-color !default;

$btn-disabled-color: $ui-disabled-color !default;
$btn-disabled-opacity: 0.3 !default;

// Button size.
$btn-width: 38px !default;
$btn-height: 40px !default;
$btn-margin: 4px !default;
$btn-font-size: 24px !default;
$btn-more-toolbar-height: 34px !default;
$btn-border-radius: 4px !default;
$btn-dropdown-border-radius: 0 $btn-border-radius $btn-border-radius 0 !default;
$btn-hover-border-radius: $btn-border-radius 0 0 $btn-border-radius !default;
$btn-svg-size: $ui-svg-size !default;
$btn-tab-border-radius: 6px 6px 0 0 !default;

// Second toolbar.
$more-toolbar-margin: 0 17px 0 12px !default;
$more-toolbar-margin-sm: 0 7px 0 6px !default;
$more-toolbar-btn-height: $btn-height + ($btn-margin * 2) !default;

// Image.
$image-margin: 5px !default;
$image-padding: 0 1px !default;

// Image and Video
$handler-size: 12px !default;
$handler-size-lg: 10px !default;

// Code View
$code-view-bg: $white !default;
$code-view-text: $black !default;

// Table properties.
$table-border: 1px solid #DDD !default;
$table-resizer: 1px solid $ui-color !default;

// Insert table grid.
$insert-table-grid: $table-border !default;

// Quick insert.
$floating-btn-bg: $white !default;
$floating-btn-text: $light-black !default;
$floating-btn-hover-bg: $btn-hover-bg !default;
$floating-btn-hover-text: $floating-btn-text !default;
$floating-btn-size: 40px !default;
$floating-btn-font-size: 14px !default;
$floating-btn-border: none !default;
$floating-svg-btn-size: $ui-svg-size !default;
$floating-svg-btn-margin: 8px !default;
$floating-btn-padding: 6px 10px 10px 10px !default;
$floating-btn-box-shadow: $ui-box-shadow !default;

$quick-insert-padding-right: 10px !default;

// List menu.
$dropdown-arrow-width: 4px !default;
$dropdown-max-height: 275px !default;
$dropdown-options-width: 16px !default;
$dropdown-options-margin-left: -5px !default;
$dropdown-options-border-left: solid 1px #FAFAFA !default;
$dropdown-transition-timing: 0.3s !default;
$dropdown-border-radius: 4px !default;
$dropdown-list-padding: 8px 0 !default;
$dropdown-list-min-width: 72px !default;
$dropdown-list-item-font-size: 15px !default;
$dropdown-menu-box-shadow: $ui-box-shadow !default;

// Image manager.
$modal-bg: $white !default;
$modal-overlay-color: $black !default;
$modal-head-height: 56px !default;
$modal-head-padding: 0 10px !default;
$modal-btn-padding: ($floating-btn-size - $floating-svg-btn-size) / 2 !default;
$modal-btn-margin-top: 10px !default;
$modal-tags-border-radius: 32px !default;
$modal-tags-padding: 0 20px !default;
$modal-link-tags-padding: 6px 12px !default;
$modal-link-tags-margin: 8px 0 8px 8px !default;
$modal-selected-tag-bg: #EcF5Ff !default;
$modal-wrapper-border-radius: 10px !default;
$modal-wrapper-margin-lg: 20px auto !default;
$modal-wrapper-margin-md: 50px auto !default;
$modal-wrapper-margin-sm: 30px auto !default;
$modal-wrapper-box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12) !default;
$modal-head-min-height: 42px !default;
$modal-close-margin: 10px !default;
$modal-heading-font-size: 20px !default;
$modal-heading-padding: 19px 10px !default;
$modal-body-min-width: 150px !default;
$modal-body-btn-height: $btn-size !default;
$modal-body-btn-padding: 10px !default;
$modal-body-padding-bottom: 20px !default;
$modal-head-line-height: 18px !default;
$modal-wrapper-min-width: 300px !default;
$modal-overlay-opacity: 0.5 !default;

// Destroy buttons (delete in image manager).
$modal-destroy-btn-bg: #B8312F !default;
$modal-destroy-btn-text: $white !default;
$modal-destroy-btn-hover-bg: mix(contrast-color($modal-destroy-btn-bg, $black, $white), $modal-destroy-btn-bg, 10%) !default;
$modal-destroy-btn-hover-text: $white !default;

// Popups
$popup-layer-width: 260px !default;
$popup-border-radius: 6px !default;
$popup-min-width: 276px !default;
$popup-container-max-height: 200px !default;
$popup-icon-size: $ui-svg-size !default;
$popup-tab-button-width: 46px !default;
$popup-tab-button-height: $btn-height !default;
$popup-box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12) !default;
$popup-action-btn-spacing: 10px !default;
$popup-margin-top: $popup-action-btn-spacing !default;
$popup-layer-margin: 20px !default;
$popup-action-button-font-size: 16px !default;
$popup-only-btn-padding: 6px 10px !default;
$popup-btn-svg-size: $ui-svg-size !default;
$popup-border-margin: 20px !default;
$popup-border-padding: 20px !default;
$popup-icon-font-size: 20px !default;
$popup-padding: 5px !default;
$popup-icon-padding: 6px !default;
$popup-emoticon-container-width: 368px !default;
$popup-sc-container-width: $popup-emoticon-container-width !default;
$popup-fa-container-width: 506px !default;
$popup-sm-ie-width: 236px !default;
$popup-fa-icon-size: 18px !default;
$popup-fa-icon-padding: 8px !default;
$popup-fa-icon-margin: 6px !default;
$popup-fa-icon-font-size: 16px !default;
$popup-fa-grid-icon-size: 45px !default;

// Inputs.
$input-border-color: $dark-grey !default;
$input-label-color: mix($white, #000, 50%) !default;
$input-line-padding: 15px 0 !default;
$input-border-radius: 2px !default;
$input-border-width: 1px !default;
$input-focus-border-width: 2px !default;
$input-padding-left-right: 12px !default;
$input-padding-top-bottom: 4px !default;
$input-padding: $input-padding-top-bottom $input-padding-left-right !default;
$input-focus-padding: ($input-padding-top-bottom - $input-focus-border-width + $input-border-width) ($input-padding-left-right - $input-focus-border-width + $input-border-width) !default;
$input-height: 46px !default;
$input-label-font-size: 14px !default;
$input-label-font-size-after: 11px !default;
$input-label-top: 29px !default;
$input-label-top-after: 9px !default;
$input-label-left: 12px !default;
$input-label-left-after: 4px !default;
$input-label-padding: 4px !default;
$input-margin-bottom: 1px !default;

// Checkbox
$checkbox-size: 18px !default;
$checkbox-padding: 10px !default;
$checkbox-margin: 2px !default;
$checbox-svg-size: 10px !default;
$checkbox-line-margin-top: 10px !default;
$checkbox-line-font-size: 14px !default;
$checkbox-line-label-margin: 0 5px !default;
$checkbox-line-height: 1.4px !default;
$checbox-active-bg: #EcF5Ff !default;
$checkbox-opacity: 0.8 !default;

// Quote.
$blockquote-level1-color: #5E35B1 !default;
$blockquote-level2-color: #00BCD4 !default;
$blockquote-level3-color: #43A047 !default;

// Char counter.
$char-counter-text-color: $dark-grey !default;
$char-counter-font-size: 14px !default;
$char-counter-font-family: sans-serif !default;
$char-counter-padding: 10px !default;

$drag-helper-border: solid 1px $ui-color !default;
$drag-helper-bg: $ui-color !default;

// Document ready.
$document-ready-padding: 30px !default;
$document-ready-bg-color: $ui-bg-color !default;

// Colors
$color-input-line-padding: 15px 0 0 !default;
$color-action-button-padding: 17px 0 0 !default;
$color-span-size: 32px !default;
$color-remove-svg-margin: 4px !default;
$color-line-height: 32px !default;
$color-font-size: 13px !default;

// Emoticons
$emoticon-tab-padding: 6px !default;
$emoticon-tab-btn-padding: 0 0 0 14px !default;

// Image Manager
$image-manager-list-margin: 0 20px !default;
$image-manager-not-available-padding: 12px 0 !default;

//Special Character
$special-char-btn-padding: 10px 15px !default;

