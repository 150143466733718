.vc-list-columns-toggler {
    position: relative;
}

.vc-list-columns-toggler__select {
    position: absolute;
    width: 200px;
    right: 100%;
    top: 0;
}
