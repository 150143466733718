.fr-element, .fr-element:focus {
  outline: 0 solid transparent;
}

.fr-box.fr-basic {
  @include border-radius($editor-border-radius);

  .fr-element {
    font-family: $editor-font-family;
    color: $editor-font-color;
    font-size: $editor-font-size;
    line-height: $editor-line-height;

    padding: $editor-padding;
    @include box-sizing(border-box);
    overflow-x: auto;
    min-height: (20px + (2 * $editor-padding));
    text-align: $editor-align;
  }

  &.fr-rtl {
    .fr-element {
      text-align: right;
    }
  }
}

.fr-element {
  background: transparent;
  position: relative;
  z-index: 2;

  // CSS rule for iPad not being able to select sometimes.
  -webkit-user-select: auto;

  // Fix bootstrap select.
  a {
    @include user-select(auto);
  }

  &.fr-disabled {
    @include user-select(none);
  }

  [contenteditable="true"] {
    outline: 0 solid transparent;
  }
}

.fr-box {
  a.fr-floating-btn {
    @include box-shadow($floating-btn-box-shadow);
    @include border-radius(100%);
    height: $floating-btn-size;
    width: $floating-btn-size;
    text-align: center;
    background: $floating-btn-bg;
    color: $floating-btn-text;
    @include transition(background $transition-timing, color $transition-timing, transform $transition-timing);
    outline: none;
    left: 0;
    top: 0;
    line-height: ($floating-btn-size);
    @include transform(scale(0));
    text-align: center;
    display: block;
    @include box-sizing(border-box);
    border: $floating-btn-border;

    svg {
      @include transition(transform $transition-timing);
      fill: $floating-btn-text;
    }

    i, svg {
      font-size: $floating-btn-font-size;
      line-height: $floating-btn-size;
    }

    &.fr-btn + .fr-btn {
      margin-left: 10px;
    }

    &:hover {
      background: $floating-btn-hover-bg;
      cursor: pointer;

      svg {
        fill: $floating-btn-hover-text;
      }
    }
  }

  .fr-visible {
    a.fr-floating-btn {
      @include transform(scale(1));
    }
  }
}

